import styled, { css } from 'styled-components';
import { Form } from '@edx/paragon';

const StyledFormControlFeedback = styled(Form.Control.Feedback)`
  font-family: 'Open Sans', sans-serif;
  font-size: 0.875rem;
  line-height: 1.125rem;
  margin-top: ${(props) => props.mt || '.25rem'};

${({ type, theme: { FEEDBACK_THEME } = {} }) => type
    && FEEDBACK_THEME && FEEDBACK_THEME[type]
    && css`
       color: ${FEEDBACK_THEME[type].color};
   `}

`;

export default StyledFormControlFeedback;
