import styled, { css } from 'styled-components';
import { StatefulButton } from '@edx/paragon';

const StyledButton = styled(StatefulButton)`
font-weight: 600;
font-family: 'Open Sans', sans-serif;
padding: ${props => (props.variant === 'link' ? '0.41rem' : '0.47rem 1.938rem')};
line-height: ${props => (props.variant === 'link' ? '1.333' : '1.5')};
border-radius: 2px;

${({ size, theme: { BUTTON_THEME_SIZE } = {} }) => size
    && BUTTON_THEME_SIZE && BUTTON_THEME_SIZE[size]
    && css`
       padding: ${BUTTON_THEME_SIZE[size].padding};
       font-size: ${BUTTON_THEME_SIZE[size].fontSize};
       line-height: ${BUTTON_THEME_SIZE[size].lineHeight};
   `}

${({ variant, theme: { BUTTON_THEME } = {} }) => variant
    && BUTTON_THEME && BUTTON_THEME[variant]
    && css`
       &.btn-${variant}, &.btn-${variant}:disabled, &.btn-${variant}.disabled {
        background-color: ${BUTTON_THEME[variant].backgroundColor};
        color: ${BUTTON_THEME[variant].color};
        border: ${BUTTON_THEME[variant].border};
        border-color: ${BUTTON_THEME[variant].borderColor};
        border-radius: ${BUTTON_THEME[variant].borderRadius};
        box-shadow: ${BUTTON_THEME[variant].boxShadow};
        text-decoration: ${BUTTON_THEME[variant].textDecoration};
        font-size: ${BUTTON_THEME[variant].fontSize};
        line-height: ${BUTTON_THEME[variant].lineHeight}
       }
       &.btn-${variant}:not(:disabled):not(.disabled):active, &.btn-${variant}:active {
        background-color: ${BUTTON_THEME[variant].backgroundColorActive};
        color: ${BUTTON_THEME[variant].colorActive};
        border-color: ${BUTTON_THEME[variant].borderColor};
        border-radius: ${BUTTON_THEME[variant].borderRadius};
        box-shadow: ${BUTTON_THEME[variant].boxShadowHover};
        text-decoration: ${BUTTON_THEME[variant].textDecoration};
        border: ${BUTTON_THEME[variant].border};
       }
       &.btn-${variant}:not(:disabled):not(.disabled):hover, &.btn-${variant}:hover, &.btn-${variant}:not(:disabled):focus {
        background-color: ${BUTTON_THEME[variant].backgroundColorHover};
        color: ${BUTTON_THEME[variant].colorHover};
        border: ${BUTTON_THEME[variant].borderHover};
        border-color: ${BUTTON_THEME[variant].borderColorHover};
        border-radius: ${BUTTON_THEME[variant].borderRadius};
        box-shadow: ${BUTTON_THEME[variant].boxShadowHover};
        text-decoration: ${BUTTON_THEME[variant].textDecoration};
     }
      &.btn-${variant}:focus:before {
        border: solid 2px #294766;
        border-radius: ${BUTTON_THEME[variant].borderRadius} !important;
      }
   `}
`;

export default StyledButton;
