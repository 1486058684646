export const ALERT_THEME = {
  info: {
    backgroundColor: ({ theme }) => theme.secondaryColor,
    color: ({ theme }) => theme.primaryColorDark,
    border: `1px solid ${({ theme }) => theme.secondaryColor}`,
    borderRadius: '0',
    boxShadow: 'none',
    padding: '1.5rem',
  },
  primary: {
    backgroundColor: ({ theme }) => theme.secondaryColor,
    color: ({ theme }) => theme.primaryColorDark,
    border: `1px solid ${({ theme }) => theme.secondaryColor}`,
    borderRadius: '0',
    boxShadow: 'none',
    padding: '1.5rem',
  },
  secondary: {
    backgroundColor: '#DEDFDF',
    color: '#686970',
    border: '1px solid #DEDFDF',
    borderRadius: '0',
    boxShadow: 'none',
    padding: '1.5rem',
  },
  success: {
    backgroundColor: ({ theme }) => theme.successColorLight,
    color: ({ theme }) => theme.successColorDark,
    border: `1px solid ${({ theme }) => theme.successColorLight}`,
    borderRadius: '0',
    boxShadow: 'none',
    padding: '1.5rem',
  },
  danger: {
    backgroundColor: ({ theme }) => theme.errorColorLight,
    color: ({ theme }) => theme.errorColorDark,
    border: 'none',
    borderRadius: '0',
    boxShadow: 'none',
    padding: '1.5rem',
  },
  warning: {
    backgroundColor: ({ theme }) => theme.errorColorLight,
    color: ({ theme }) => theme.errorColorDark,
    border: 'none',
    borderRadius: '0',
    boxShadow: 'none',
    padding: '1.5rem',
  },
  error: {
    backgroundColor: ({ theme }) => theme.errorColorLight,
    color: ({ theme }) => theme.errorColorDark,
    border: 'none',
    borderRadius: '0',
    boxShadow: 'none',
    padding: '1.5rem 5rem',
  },
};

export const ALERT_THEME_SIZE = {
  sm: {
    fontSize: '1rem',
    lineHeight: '1.375rem',
  },
  lg: {
    fontSize: '1.125rem',
    lineHeight: '1.625rem',
  },
};
