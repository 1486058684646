import React from 'react';
import PropTypes from 'prop-types';
import StyledFormLabel from './styled';

/**
 * Primary UI component for user interaction
 */
export const FormLabel = ({
  children,
  isRequired,
  ...props
}) => (

  <StyledFormLabel
    {...props}
  >
    {isRequired ? (
      <>
        {children}
        <span className="asteriks-icon" aria-hidden="true">&nbsp;*</span>
      </>
    ) : children}
  </StyledFormLabel>
);

FormLabel.propTypes = {
  isInline: PropTypes.bool,
  size: PropTypes.oneOf([
    'sm',
    'lg',
  ]),
  className: PropTypes.string,
  children: PropTypes.node.isRequired,
  isRequired: PropTypes.bool,
};

FormLabel.defaultProps = {
  isInline: false,
  size: undefined,
  className: undefined,
  isRequired: false,
};
