import React from 'react';
import PropTypes from 'prop-types';
import StyledButton from './styled';

/**
 * Primary UI component for user interaction
 */
export const Button = ({ children, buttonRef, ...props }) => {
  if (props.variant === 'link') {
    return (
      <StyledButton
        ref={buttonRef}
        {...props}
      >
        <span className="btn-text">{children}</span>
      </StyledButton>
    );
  }

  return (
    <StyledButton
      ref={buttonRef}
      {...props}
    >
      {children}
    </StyledButton>
  );
};

Button.propTypes = {
  /**
   * Is full width button?border-bottom: 1px solid #10477E;
   */
  block: PropTypes.bool,
  /**
   * What variant type should be?
   */
  variant: PropTypes.oneOf(['brand', 'outline-brand', 'primary', 'outline-primary', 'tertiary', 'link', 'success', 'danger', 'outline-success', 'outline-danger', 'icon']),
  /**
   * How large should the button be?
   */
  size: PropTypes.oneOf(['sm', 'lg', '']),
  /**
   * Button contents
   */
  children: PropTypes.node.isRequired,
  /**
   * Optional click handler
   */
  onClick: PropTypes.func,
  buttonRef: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.shape({ current: PropTypes.any }),
  ]),
};

Button.defaultProps = {
  variant: 'brand',
  block: false,
  size: 'lg',
  onClick: undefined,
  buttonRef: null,
};
