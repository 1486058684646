export const FEEDBACK_THEME = {
  default: {
    color: ({ theme }) => theme.textColor2,
  },
  valid: {
    color: '#008080',
  },
  invalid: {
    color: ({ theme }) => theme.errorColor,
  },
};
