export const BUTTON_THEME = {
  link: {
    color: ({ theme }) => theme.primaryColor,
    colorActive: ({ theme }) => theme.primaryColor,
    colorHover: ({ theme }) => theme.primaryColorDark,
    borderRadius: '2px',
    textDecoration: 'none',
    fontSize: '0.875rem',
    lineHeight: '1.333',
  },
  brand: {
    backgroundColor: ({ theme }) => theme.primaryColor,
    color: '#ffffff',
    backgroundColorHover: ({ theme }) => theme.primaryColorDark,
    colorHover: '#ffffff',
    backgroundColorActive: ({ theme }) => theme.primaryColorDark,
    colorActive: '#ffffff',
    border: `1px solid ${({ theme }) => theme.primaryColor}`,
    borderColor: ({ theme }) => theme.primaryColor,
    borderHover: `1px solid ${({ theme }) => theme.primaryColorDark}`,
    borderColorHover: ({ theme }) => theme.primaryColorDark,
    borderRadius: '2px',
    boxShadow: '0px 2px 8px rgba(24, 104, 184, 0.4)',
    boxShadowHover: '0px 2px 8px rgba(21, 92, 162, 0.4)',
    textDecoration: 'none',
    lineHeight: '1.5',
  },
  primary: {
    backgroundColor: ({ theme }) => theme.primaryColor,
    color: '#ffffff',
    backgroundColorHover: ({ theme }) => theme.primaryColorDark,
    colorHover: '#ffffff',
    backgroundColorActive: ({ theme }) => theme.primaryColorDark,
    colorActive: '#ffffff',
    border: `1px solid ${({ theme }) => theme.primaryColor}`,
    borderColor: ({ theme }) => theme.primaryColor,
    borderHover: `1px solid ${({ theme }) => theme.primaryColorDark}`,
    borderColorHover: ({ theme }) => theme.primaryColorDark,
    borderRadius: '2px',
    boxShadow: '0px 2px 8px rgba(24, 104, 184, 0.4)',
    boxShadowHover: '0px 2px 8px rgba(21, 92, 162, 0.4)',
    textDecoration: 'none',
    lineHeight: '1.5',
  },
  'outline-brand': {
    backgroundColor: '#ffffff',
    color: ({ theme }) => theme.primaryColor,
    backgroundColorHover: ({ theme }) => theme.secondaryColor,
    colorHover: ({ theme }) => theme.primaryColorDark,
    backgroundColorActive: ({ theme }) => theme.secondaryColor,
    colorActive: ({ theme }) => theme.primaryColorDark,
    border: `1px solid ${({ theme }) => theme.primaryColor}`,
    borderColor: ({ theme }) => theme.primaryColor,
    borderHover: `1px solid ${({ theme }) => theme.primaryColorDark}`,
    borderColorHover: ({ theme }) => theme.primaryColorDark,
    borderRadius: '2px',
    boxShadow: 'none',
    boxShadowHover: 'none',
    textDecoration: 'none',
    lineHeight: '1.5',
  },
  'outline-primary': {
    backgroundColor: '#ffffff',
    color: ({ theme }) => theme.primaryColor,
    backgroundColorHover: ({ theme }) => theme.secondaryColor,
    colorHover: ({ theme }) => theme.primaryColorDark,
    backgroundColorActive: ({ theme }) => theme.secondaryColor,
    colorActive: ({ theme }) => theme.primaryColorDark,
    border: `1px solid ${({ theme }) => theme.primaryColor}`,
    borderColor: ({ theme }) => theme.primaryColor,
    borderHover: `1px solid ${({ theme }) => theme.primaryColorDark}`,
    borderColorHover: ({ theme }) => theme.primaryColorDark,
    borderRadius: '2px',
    boxShadow: 'none',
    boxShadowHover: 'none',
    textDecoration: 'none',
    lineHeight: '1.5',
  },
  icon: {
    backgroundColor: 'transparent',
    color: 'transparent',
    backgroundColorHover: 'transparent !important',
    colorHover: 'transparent',
    backgroundColorActive: 'transparent',
    colorActive: 'transparent',
    border: '1px solid transparent',
    borderColor: 'transparent',
    borderHover: '1px solid transparent',
    borderColorHover: 'transparent',
    borderRadius: '0',
    boxShadow: 'none !important',
    boxShadowHover: 'none !important',
    textDecoration: 'none',
  },
};

export const BUTTON_THEME_SIZE = {
  sm: {
    padding: '0.44rem 1.625rem',
    fontSize: '1rem',
    lineHeight: '1.42858',
  },
  lg: {
    padding: '0.47rem 1.938rem',
    fontSize: '1.125rem',
    lineHeight: '1.5',
  },
};
