import React from 'react';
import PropTypes from 'prop-types';
import StyledFormFrame from './styled';

/**
 * Form Frame containing form fields
 */
export const FormFrame = ({
  children,
  width,
  ...props
}) => (
  <StyledFormFrame
    {...props}
  >
    {children}
  </StyledFormFrame>
);

FormFrame.propTypes = {
  /** Maximum width for container */
  width: PropTypes.string,
  children: PropTypes.node.isRequired,
  /** Class name */
  className: PropTypes.string,
};

FormFrame.defaultProps = {
  width: '35.25rem',
  className: undefined,
};
