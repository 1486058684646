import styled, { css } from 'styled-components';
import { Alert } from '@edx/paragon';

const StyledAlert = styled(Alert)`
font-weight: ${(props) => (props.fontWeight === 'semi-bold' ? '600' : props.fontWeight)};
font-family: 'Open Sans', sans-serif;
min-height: ${props => props.height || '4.125rem'};
text-align: ${(props) => props.align};
display: block;

${({ size, theme: { ALERT_THEME_SIZE } = {} }) => size
    && ALERT_THEME_SIZE && ALERT_THEME_SIZE[size]
    && css`
       font-size: ${ALERT_THEME_SIZE[size].fontSize};
       line-height: ${ALERT_THEME_SIZE[size].lineHeight};
   `}

${({ variant, theme: { ALERT_THEME } = {} }) => variant
    && ALERT_THEME && ALERT_THEME[variant]
    && css`
       &.alert-${variant} {
        background-color: ${ALERT_THEME[variant].backgroundColor};
        color: ${ALERT_THEME[variant].color};
        border: ${ALERT_THEME[variant].border};
        border-radius: ${ALERT_THEME[variant].borderRadius};
        box-shadow: ${ALERT_THEME[variant].boxShadow};
        padding: ${(props) => props.padding || ALERT_THEME[variant].padding};
        position: relative;
        outline: none;

        @media screen and (max-width: 767px) {
          padding: ${variant === 'error' ? '1.5rem 1rem' : ALERT_THEME[variant].padding};
        }

        ${({ showfocus }) => showfocus && css`
          &:focus:before {
            content: "";
            position: absolute;
            top: -5px;
            right: -5px;
            bottom: -5px;
            left: -5px;
            border: solid 2px ${({ theme }) => theme && theme.focusColorKeyboard};
            border-radius: 2px;
          }
        `}

        .close, .close:hover, .close:focus {
          color: ${ALERT_THEME[variant].color};
          line-height: unset;
        }

        .alert-heading {
          color: ${ALERT_THEME[variant].color};
          font-weight: 600;
          margin: 0;
        }

        .alert-link, .alert-link:hover, .alert-link:focus {
          color: ${ALERT_THEME[variant].color} !important;
        }

        & button.btn-link:not(:disabled):not(.disabled), & button.btn-link:not(:disabled):not(.disabled):hover {
          color: inherit;

          & .btn-text {
            border-bottom-color: inherit;
            border-bottom: 1px solid;
          }
        }
       }
   `}
`;

export default StyledAlert;
