import styled, { css } from 'styled-components';
import { Form } from '@edx/paragon';

const StyledFormLabel = styled(Form.Label)`
  font-family: 'Open Sans', sans-serif;
  font-weight: 600;
  font-size: 1rem;
  line-height: 1.625;
  color: ${({ theme: { LABEL_THEME } = {} }) => LABEL_THEME && LABEL_THEME.color};
  display: inline-block;

  &&& {
    margin-bottom: 0.25rem;
  }

  ${({ size, theme: { LABEL_THEME_SIZE } = {} }) => size
    && LABEL_THEME_SIZE && LABEL_THEME_SIZE[size]
    && css`
        font-size: ${LABEL_THEME_SIZE[size].fontSize};
        line-height: ${LABEL_THEME_SIZE[size].lineHeight};
 `}

 .asteriks-icon {
     color: ${({ theme: { LABEL_THEME } = {} }) => LABEL_THEME && LABEL_THEME.colorError};
     font-weight: 600;
 }

`;

export default StyledFormLabel;
