const blueLight = '#F6F9FC';
const blue = '#1868B9';
const blueDark = '#155CA2';
const white = '#FFFFFF';
const red = '#D71932';
const red2 = '#E41D37';
const redLight = '#FDEDEF';
const green = '#396A6A';
const greenLight = '#E3F0F0';
const grey = '#DEE2E6';

const TEXT_COLORS = {
  textColor1: '#1C2D3D',
  textColor2: '#4A5866',
  textColor3: '#66737F',
};

export const COLORS = {
  primaryColor: blue,
  primaryColorLight: blue,
  primaryColorDark: blueDark,
  secondaryColor: blueLight,
  secondaryColorLight: white,
  secondaryColorDark: blueLight,
  successColorLight: greenLight,
  successColorDark: green,
  errorColorLight: redLight,
  errorColorDark: red,
  errorColor: red2,
  lineColor: grey,
  focusColorKeyboard: '#294766',
  backgroundBodyColor: '#FCFCFC',
  ...TEXT_COLORS,
};
