import React from 'react';
import PropTypes from 'prop-types';
import { Alert as _Alert } from '@edx/paragon';
import StyledAlert from './styled';

/**
 * Alert
 */
export const Alert = ({
  children,
  padding,
  alertRef,
  ...props
}) => (
  <StyledAlert
    ref={alertRef}
    {...props}
  >
    {children}
  </StyledAlert>
);

Alert.Heading = _Alert.Heading;
Alert.Link = _Alert.Link;

Alert.propTypes = {
  /** Child node */
  children: PropTypes.node.isRequired,
  /** Variant type
    */
  variant: PropTypes.oneOf(['error', 'danger', 'warning', 'info', 'success', 'primary', 'secondary']),
  /** Add the dismissible (true / false) prop to add a functioning dismiss button to the Alert.
    */
  dismissible: PropTypes.bool,
  /** specifies the callback function */
  onClick: PropTypes.func,
  onClose: PropTypes.func,
  /** Controls the visual state of the Alert. */
  show: PropTypes.bool,
  /** Sets the text for alert close button. */
  closeLabel: PropTypes.string,
  /** Text align, by default is centered */
  align: PropTypes.oneOf(['center', 'left', 'right']),
  /** Padding */
  padding: PropTypes.string,
  /** Minimum height */
  height: PropTypes.string,
  /**
   * Size
   */
  size: PropTypes.oneOf([
    'sm',
    'lg',
  ]),
  fontWeight: PropTypes.oneOf([
    'normal',
    'semi-bold',
    'bold',
  ]),
  tabIndex: PropTypes.oneOf([0, -1]),
  showfocus: PropTypes.bool,
  alertRef: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.shape({ current: PropTypes.any }),
  ]),
};

Alert.defaultProps = {
  variant: undefined,
  size: 'sm',
  fontWeight: 'normal',
  dismissible: false,
  closeLabel: undefined,
  show: undefined,
  align: 'center',
  padding: undefined,
  height: undefined,
  onClick: undefined,
  onClose: undefined,
  tabIndex: -1,
  showfocus: false,
  alertRef: null,
};
