import { createGlobalStyle } from 'styled-components';

export const GlobalStyle = createGlobalStyle`

  body {
    font-size: ${({ theme }) => theme.fontSizeBody || '1rem'};
    font-family: ${({ theme }) => theme.fontFamily || '\'Open Sans\', sans-serif'};
    background-color: ${({ theme }) => theme.backgroundBodyColor || '#FCFCFC'};
  }
`;
