import styled, { css } from 'styled-components';
import { Form, Tooltip } from '@edx/paragon';

const StyledInput = styled(Form.Control)`
  position: relative;
  :focus-within::before {
    content: "";
    position: absolute;
    top: -3px;
    right: -3px;
    bottom: -3px;
    left: -3px;
    border: solid 3px ${({ theme: { INPUT_THEME } = {} }) => INPUT_THEME && INPUT_THEME.borderColorOutline};
    border-radius: 5px;
    opacity: 0;

    [data-whatinput='mouse'] & {
      opacity: 1;
    }
    [data-whatinput='keyboard'] & {
      opacity: 1;
    }
  }

  :focus-within::after {
    content: "";
    position: absolute;
    top: -3px;
    right: -3px;
    bottom: -3px;
    left: -3px;
    border: 2px solid ${({ theme }) => theme && theme.focusColorKeyboard};
    border-radius: 4px;
    opacity: 0;

    [data-whatinput='keyboard'] & {
      opacity: 1;
    }
  }

  &&& .form-control {
    ${({ hasIcon, iconPosition }) => hasIcon && iconPosition === 'right' && css`
    padding-right: calc(0.75rem + 32px);
    padding-left: 0.75rem;
  `}
  }

  .form-control {
    background-color: #ffffff;
    border: 0.5px solid ${({ theme: { INPUT_THEME } = {} }) => INPUT_THEME && INPUT_THEME.borderColor};
    border-radius: 0.125rem;
    color: ${({ theme: { INPUT_THEME } = {} }) => INPUT_THEME && INPUT_THEME.color};
    font-size: 1rem;
    line-height: 1.125rem;
    font-family: 'Open Sans', sans-serif;
    position: relative;

    ${({ size, theme: { INPUT_THEME_SIZE } = {} }) => size
    && INPUT_THEME_SIZE && INPUT_THEME_SIZE[size]
    && css`
       height: ${INPUT_THEME_SIZE[size].height};
       font-size: ${INPUT_THEME_SIZE[size].fontSize};
       line-height: ${INPUT_THEME_SIZE[size].lineHeight};
   `}

   ::-webkit-input-placeholder {
    color: ${({ theme: { INPUT_THEME } = {} }) => INPUT_THEME && INPUT_THEME.color};
    opacity: 1;
   }

   :-moz-placeholder { /* Firefox 18- */
    color: ${({ theme: { INPUT_THEME } = {} }) => INPUT_THEME && INPUT_THEME.color};
    opacity: 1;
   }

   ::-moz-placeholder {  /* Firefox 19+ */
    color: ${({ theme: { INPUT_THEME } = {} }) => INPUT_THEME && INPUT_THEME.color};
    opacity: 1;
   }

   :-ms-input-placeholder {
    color: ${({ theme: { INPUT_THEME } = {} }) => INPUT_THEME && INPUT_THEME.color};
    opacity: 1;
   }

   :-webkit-autofill,
   :-webkit-autofill:hover, 
   :-webkit-autofill:focus, 
   :-webkit-autofill:active {
    -webkit-box-shadow: 0 0 0 30px white inset !important;
  }

  + .pgn__form-control-decorator.pgn__form-control-decorator-leading {
    z-index: 1;
    ${({ hasIcon, iconPosition }) => hasIcon && iconPosition === 'right' && css`
      right: 0;
      left: auto;
    `}
  }

  &&& .pgn__form-control-decorator .btn-icon:focus {
    background-color: inherit;
    -webkit-box-shadow: none;
    box-shadow: none;
  }

  &&& .pgn__form-control-decorator .btn-icon:hover {
    background-color: inherit;
  }

  + .pgn__form-control-decorator.pgn__form-control-decorator-leading .btn-icon {
    height: 100%;
    padding-left: 0.688rem;
    padding-right: 0.688rem;
  }

  + .pgn__form-control-decorator-leading .pgn__icon {
    width: auto;
    height: auto;
    
    & svg {
      pointer-events: none;
    }
  }
}

  .form-control:hover, .form-control.is-valid:hover, .form-control.is-invalid:hover {
    background-color: #ffffff;
    border: 2px solid ${({ theme: { INPUT_THEME } = {} }) => INPUT_THEME && INPUT_THEME.borderColorHover};
  }

  .form-control:focus, .form-control.is-valid:focus, .form-control.is-invalid:focus {
    background-color: #ffffff;
    border: 1px solid ${({ theme: { INPUT_THEME } = {} }) => INPUT_THEME && INPUT_THEME.borderColor};
    box-shadow: none;
    outline: none;
  }

  .form-control.is-valid {
    background-color: #ffffff;
    border: 0.5px solid ${({ theme: { INPUT_THEME } = {} }) => INPUT_THEME && INPUT_THEME.borderColor};
    outline: none;
    box-shadow: none;
  }
  
  .form-control.is-invalid {
    background-color: #ffffff;
    border-top: 0.5px solid ${({ theme: { INPUT_THEME } = {} }) => INPUT_THEME && INPUT_THEME.borderColor};
    border-left: 0.5px solid ${({ theme: { INPUT_THEME } = {} }) => INPUT_THEME && INPUT_THEME.borderColor};
    border-right: 0.5px solid ${({ theme: { INPUT_THEME } = {} }) => INPUT_THEME && INPUT_THEME.borderColor};
    border-bottom: 2px solid ${({ theme: { INPUT_THEME } = {} }) => INPUT_THEME && INPUT_THEME.borderColorError};
    outline: none;
    box-shadow: none;
  }
`;

const TooltipSmall = styled.div`
    position: absolute;
    bottom: 0;
    right: 0;
    width: auto;
    height: 20px;
    transform: translate(-5px,100%);
    color: #212529;
    font-size: 0.875rem;
    line-height: 1.188rem;
    font-family: 'Open Sans', sans-serif;
    font-weight: normal;
    padding: 0.1rem 0.375rem;
    white-space: nowrap;
    text-align: center;
    border: 0.5px solid #A5A9AC;
    background-color: #FCFCFC;
    border-radius: 0;
    vertical-align: middle;
    display: flex;
    align-items: center;
    justify-content: center;
`;

const StyledTooltip = styled(Tooltip)`
  border: 0.5px solid #A5A9AC;
  background-color: #FCFCFC;
  border-radius: 0;
  padding: 0.1rem;
  filter: none;
  transform: translateX(100%);

  .tooltip-inner {
    color: #212529;
    font-size: 0.875rem;
    line-height: 1.188rem;
    font-family: 'Open Sans', sans-serif;
    font-weight: normal;
    padding: 0.1rem;
  }
`;

export {
  StyledInput,
  StyledTooltip,
  TooltipSmall,
};
