export const CHECKBOX_THEME = {
  background: '#ffffff',
  backgroundEnable: ({ theme }) => theme.primaryColor,
  border: '1px solid #66737F',
  borderEnable: ({ theme }) => theme.primaryColor,
  borderHover: '1px solid rgba(33, 37, 41, 1)',
  colorError: ({ theme }) => theme.errorColor,
  backgroundHoverBefore: '#f4f4f4',
  labelColor: ({ theme }) => theme.textColor2,
};

export const CHECKBOX_THEME_SIZE = {
  sm: {
    width: '1rem',
    height: '1rem',
    sizeBefore: '2.375rem',
    fontSize: '0.875rem',
    lineHeight: '1.25rem',
  },
  md: {
    width: '1.25rem',
    height: '1.25rem',
    sizeBefore: '2.75rem',
    fontSize: '1rem',
    lineHeight: '1.625rem',
  },
  lg: {
    width: '1.5rem',
    height: '1.5rem',
    sizeBefore: '3.25rem',
    fontSize: '1.25rem',
    lineHeight: '1.75rem',
  },
};
