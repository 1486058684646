export const TEXT_THEME_SIZE = {
  sm: {
    fontSize: '0.875rem',
    lineHeight: '1.25rem',
  },
  lg: {
    fontSize: '1rem',
    lineHeight: '1.625rem',
  },
};
