import React from 'react';
import PropTypes from 'prop-types';
import StyledButton from './styled';

/**
 * Primary UI component for user interaction
 */
export const StatefulButton = ({ ...props }) => (
  <StyledButton
    {...props}
  />
);

StatefulButton.propTypes = {
  /**
   * Is full width button?
   */
  block: PropTypes.bool,
  /**
   * What variant type should be?
   */
  variant: PropTypes.oneOf(['brand', 'outline-brand', 'primary', 'outline-primary', 'tertiary', 'link', 'success', 'danger', 'outline-success', 'outline-danger']),
  /**
   * How large should the button be?
   */
  size: PropTypes.oneOf(['sm', 'lg', '']),
  /**
   * Button state of label type
   */
  state: PropTypes.string,
  /**
   * Button contents of textas label on each state
   */
  labels: PropTypes.objectOf(PropTypes.node).isRequired,
  /**
   * Icon for each state
   */
  icons: PropTypes.objectOf(PropTypes.node),
  /**
   * By default sach state has a `disabledState`
   */
  disabledStates: PropTypes.arrayOf(PropTypes.string),
  /**
   * Optional click handler
   */
  onClick: PropTypes.func,
  /**
   * Class name (string for one class, array for multiple classes)
   */
  className: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.array,
    PropTypes.object,
  ]),
};

StatefulButton.defaultProps = {
  variant: 'brand',
  block: false,
  size: '',
  onClick: undefined,
  className: undefined,
  state: 'default',
  icons: {
    default: undefined,
    pending: undefined,
    complete: undefined,
    error: undefined,
  },
  disabledStates: ['pending', 'complete'],
};
