import styled, { css } from 'styled-components';
import { Input } from '../Input';

const StyledSelectWrapper = styled.div`
  position: relative;
`;

const StyledSelect = styled(Input).attrs({
  className: 'form-control',
})`
  &&&.form-control {
    background-color: #ffffff;
    border: 0.5px solid ${({ theme: { INPUT_THEME } = {} }) => INPUT_THEME && INPUT_THEME.borderColor};
    border-radius: 0.125rem;
    color: ${({ theme: { INPUT_THEME } = {} }) => INPUT_THEME && INPUT_THEME.color};
    font-size: 1rem;
    line-height: 1.125rem;
    font-family: 'Open Sans', sans-serif;
    width: 100%;
    padding: 0.45rem 0.75rem;
    position: relative;

    ::-webkit-input-placeholder {
      color: ${({ theme: { INPUT_THEME } = {} }) => INPUT_THEME && INPUT_THEME.color};
      opacity: 1;
    }

    :-moz-placeholder { /* Firefox 18- */
      color: ${({ theme: { INPUT_THEME } = {} }) => INPUT_THEME && INPUT_THEME.color};
      opacity: 1;
    }

    ::-moz-placeholder {  /* Firefox 19+ */
      color: ${({ theme: { INPUT_THEME } = {} }) => INPUT_THEME && INPUT_THEME.color};
      opacity: 1;
    }

    :-ms-input-placeholder {
      color: ${({ theme: { INPUT_THEME } = {} }) => INPUT_THEME && INPUT_THEME.color};
      opacity: 1;
    }
  }

  ${({ size, theme: { INPUT_THEME_SIZE } = {} }) => size
    && INPUT_THEME_SIZE && INPUT_THEME_SIZE[size]
    && css`
       height: ${INPUT_THEME_SIZE[size].height};
       font-size: ${INPUT_THEME_SIZE[size].fontSize};
       line-height: ${INPUT_THEME_SIZE[size].lineHeight};
  `}

  &&&.form-control:hover, &.form-control.is-valid:hover, &.form-control.is-invalid:hover {
    background-color: #ffffff;
    border: 2px solid ${({ theme: { INPUT_THEME } = {} }) => INPUT_THEME && INPUT_THEME.borderColorHover};
  }

  &&&.form-control:focus, &.form-control.is-valid:focus, &.form-control.is-invalid:focus {
    background-color: #ffffff;
    border: 0.5px solid ${({ theme: { INPUT_THEME } = {} }) => INPUT_THEME && INPUT_THEME.borderColor};
    outline: none;
    box-shadow: none;

    & + .focus-select:before {
      content: "";
      position: absolute;
      top: -3px;
      right: -3px;
      bottom: -3px;
      left: -3px;
      border: 2px solid ${({ theme }) => theme && theme.focusColorKeyboard};
      border-radius: 4px;
      opacity: 0;

      [data-whatinput='keyboard'] & {
        opacity: 1;
      }
    }

    & + .focus-select {
      content: "";
      position: absolute;
      top: -3px;
      right: -3px;
      bottom: -3px;
      left: -3px;
      border: solid 3px ${({ theme: { INPUT_THEME } = {} }) => INPUT_THEME && INPUT_THEME.borderColorOutline};
      border-radius: 5px;
    }
  }

  &&&.form-control.is-valid{
    background-color: #ffffff;
    border: 0.5px solid ${({ theme: { INPUT_THEME } = {} }) => INPUT_THEME && INPUT_THEME.borderColor} !important;
    outline: none;
    box-shadow: none;
  }
  
  &&&.form-control.is-invalid {
    background-color: #ffffff;
    border-top: 0.5px solid ${({ theme: { INPUT_THEME } = {} }) => INPUT_THEME && INPUT_THEME.borderColor};
    border-left: 0.5px solid ${({ theme: { INPUT_THEME } = {} }) => INPUT_THEME && INPUT_THEME.borderColor};
    border-right: 0.5px solid ${({ theme: { INPUT_THEME } = {} }) => INPUT_THEME && INPUT_THEME.borderColor};
    border-bottom: 2px solid ${({ theme: { INPUT_THEME } = {} }) => INPUT_THEME && INPUT_THEME.borderColorError};
    outline: none;
    box-shadow: none;
  }
  &&&.form-control {
    background-position: right 0.3rem center;
  }
`;

export {
  StyledSelect,
  StyledSelectWrapper,
};
