import React from 'react';
import PropTypes from 'prop-types';
import StyledText from './styled';

/**
 * Paragraph
 */
export const Text = ({
  children,
  ...props
}) => (
  <StyledText
    {...props}
  >
    {children}
  </StyledText>
);

Text.propTypes = {
  children: PropTypes.node.isRequired,
  /**
   * Font size type sm or lg
   */
  size: PropTypes.oneOf(['sm', 'lg', '']),
  /**
   * Color
   */
  color: PropTypes.string,
  /** Class name */
  className: PropTypes.string,
};

Text.defaultProps = {
  size: 'sm',
  color: undefined,
  className: undefined,
};
