import React from 'react';
import PropTypes from 'prop-types';
import StyledCheckbox from './styled';

/**
 * Checkbox
 */
export const Checkbox = ({
  children,
  requiredText,
  className,
  controlClassName,
  labelClassName,
  description,
  isInvalid,
  isValid,
  isRequired,
  isChecked,
  ...props
}) => (
  <StyledCheckbox
    {...props}
    aria-required={String(isRequired)}
  >
    {isRequired ? (
      <>
        {children}
        <span className="asteriks-icon" aria-hidden="true">&nbsp;*</span>
        <span className="sr-only">{requiredText}</span>
      </>
    ) : children}
  </StyledCheckbox>
);

Checkbox.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  controlClassName: PropTypes.string,
  labelClassName: PropTypes.string,
  description: PropTypes.node,
  isInvalid: PropTypes.bool,
  isValid: PropTypes.bool,
  isRequired: PropTypes.bool,
  isChecked: PropTypes.bool,
  requiredText: PropTypes.string,
  'aria-label': PropTypes.string,
  'aria-required': PropTypes.oneOf([
    'true',
    'false',
  ]),
  size: PropTypes.oneOf([
    'sm',
    'md',
    'lg',
  ]),
};

Checkbox.defaultProps = {
  className: undefined,
  controlClassName: undefined,
  labelClassName: undefined,
  description: undefined,
  isInvalid: false,
  isValid: false,
  isRequired: undefined,
  isChecked: false,
  requiredText: undefined,
  'aria-label': undefined,
  'aria-required': 'false',
  size: 'sm',
};
