import 'what-input';

import { COLORS } from './colors/index';

import { ALERT_THEME, ALERT_THEME_SIZE } from '../components/Alert/theme';
import { BUTTON_THEME, BUTTON_THEME_SIZE } from '../components/Button/theme';
import { CHECKBOX_THEME, CHECKBOX_THEME_SIZE } from '../components/Checkbox/theme';
import { FEEDBACK_THEME } from '../components/FormControlFeedback/theme';
import { FORM_FRAME_THEME } from '../components/FormFrame/theme';
import { LABEL_THEME, LABEL_THEME_SIZE } from '../components/FormLabel/theme';
import { LINK_THEME } from '../components/Hyperlink/theme';
import { INPUT_THEME, INPUT_THEME_SIZE } from '../components/Input/theme';
import { MODAL_THEME } from '../components/StandardModal/theme';
import { TEXT_THEME_SIZE } from '../components/Text/theme';

const DEFAULT_THEME = {
  ...COLORS,
  fontFamily: '\'Open Sans\', sans-serif',
  fontSizeBody: '1rem',
};

export const theme = {
  name: 'navoica-theme',
  class: 'navoica-theme',
  default: true,
  ...DEFAULT_THEME,
  ALERT_THEME,
  ALERT_THEME_SIZE,
  BUTTON_THEME,
  BUTTON_THEME_SIZE,
  CHECKBOX_THEME,
  CHECKBOX_THEME_SIZE,
  FEEDBACK_THEME,
  FORM_FRAME_THEME,
  LABEL_THEME,
  LABEL_THEME_SIZE,
  LINK_THEME,
  INPUT_THEME,
  INPUT_THEME_SIZE,
  MODAL_THEME,
  TEXT_THEME_SIZE,
};
