import styled, { css } from 'styled-components';
import { Form } from '@edx/paragon';

const StyledCheckbox = styled(Form.Checkbox)`
  
  &&& .pgn__form-checkbox-input {
    border: ${({ theme: { CHECKBOX_THEME } = {} }) => CHECKBOX_THEME && CHECKBOX_THEME.border};
    background-color: ${({ theme: { CHECKBOX_THEME } = {} }) => CHECKBOX_THEME && CHECKBOX_THEME.background};
    width: ${({ size, theme: { CHECKBOX_THEME_SIZE } = {} }) => CHECKBOX_THEME_SIZE && CHECKBOX_THEME_SIZE[size].width};
    height: ${({ size, theme: { CHECKBOX_THEME_SIZE } = {} }) => CHECKBOX_THEME_SIZE && CHECKBOX_THEME_SIZE[size].height};
    min-width: ${({ size, theme: { CHECKBOX_THEME_SIZE } = {} }) => CHECKBOX_THEME_SIZE && CHECKBOX_THEME_SIZE[size].width};
    margin-right: 0.938rem;
    transform: translateY(25%);
  }

  &&& .pgn__form-checkbox-input:not(:disabled):hover {
    box-shadow: none;
  }

  &&&.pgn__form-checkbox {
    position: relative;
    z-index: 0;
  }

  &&&.pgn__form-checkbox:not(:disabled):hover::before {
    content: '';
    position: absolute;
    z-index: -1;
    width: ${({ size, theme: { CHECKBOX_THEME_SIZE } = {} }) => CHECKBOX_THEME_SIZE && CHECKBOX_THEME_SIZE[size].sizeBefore};
    height: ${({ size, theme: { CHECKBOX_THEME_SIZE } = {} }) => CHECKBOX_THEME_SIZE && CHECKBOX_THEME_SIZE[size].sizeBefore};
    border-radius: 50%;
    background-color: ${({ theme: { CHECKBOX_THEME } = {} }) => CHECKBOX_THEME && CHECKBOX_THEME.backgroundHoverBefore};
    transform: translate(-28%,-18%);
  }

  &&& .pgn__form-checkbox-input:not(:disabled):hover {
    border: ${({ theme: { CHECKBOX_THEME } = {} }) => CHECKBOX_THEME && CHECKBOX_THEME.borderHover};
    outline: none;
  }
  & .pgn__form-checkbox-input {
    position: relative;
  }

  & .pgn__form-checkbox-input:focus-visible {
    outline: none;
  }

  & .pgn__form-checkbox-input:not(:active):not(:hover):focus:before {
    
    [data-whatinput='keyboard'] & {
      content: "";
      position: absolute;
      top: -5px;
      right: -5px;
      bottom: -5px;
      left: -5px;
      border: solid 2px ${({ theme }) => theme && theme.focusColorKeyboard};
      border-radius: 2px;
    }
  }

  &&& .pgn__form-checkbox-input:checked {
    border: ${({ theme: { CHECKBOX_THEME } = {} }) => CHECKBOX_THEME && CHECKBOX_THEME.borderEnable};
    border-color: ${({ theme: { CHECKBOX_THEME } = {} }) => CHECKBOX_THEME && CHECKBOX_THEME.backgroundEnable};
    background-size: .625rem;
    background-image: url("data:image/svg+xml,%3Csvg aria-hidden='true' focusable='false' data-prefix='fas' data-icon='check' class='svg-inline--fa fa-check fa-w-16' role='img' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 512'%3E%3Cpath fill='%23ffffff' d='M173.898 439.404l-166.4-166.4c-9.997-9.997-9.997-26.206 0-36.204l36.203-36.204c9.997-9.998 26.207-9.998 36.204 0L192 312.69 432.095 72.596c9.997-9.997 26.207-9.997 36.204 0l36.203 36.204c9.997 9.997 9.997 26.206 0 36.204l-294.4 294.401c-9.998 9.997-26.207 9.997-36.204-.001z'%3E%3C/path%3E%3C/svg%3E%0A");
    background-repeat: no-repeat;
    background-color: ${({ theme: { CHECKBOX_THEME } = {} }) => CHECKBOX_THEME && CHECKBOX_THEME.borderEnable};
    outline: none;
  }

  &&& .pgn__form-checkbox-input:checked:hover {
    border: ${({ theme: { CHECKBOX_THEME } = {} }) => CHECKBOX_THEME && CHECKBOX_THEME.borderEnable};
    border-color: ${({ theme: { CHECKBOX_THEME } = {} }) => CHECKBOX_THEME && CHECKBOX_THEME.backgroundEnable};
    outline: none;
  }

  & .pgn__form-label {
    font-family: 'Open Sans', sans-serif;
    font-weight: 600;
    color: ${({ theme: { CHECKBOX_THEME } = {} }) => CHECKBOX_THEME && CHECKBOX_THEME.labelColor};
    font-size: ${({ size, theme: { CHECKBOX_THEME_SIZE } = {} }) => CHECKBOX_THEME_SIZE && CHECKBOX_THEME_SIZE[size].fontSize};
    line-height: ${({ size, theme: { CHECKBOX_THEME_SIZE } = {} }) => CHECKBOX_THEME_SIZE && CHECKBOX_THEME_SIZE[size].lineHeight};
    display: inline-block;
  }

   ${({ isInvalid }) => isInvalid
    && css`
       border: ${({ theme: { CHECKBOX_THEME } = {} }) => CHECKBOX_THEME && CHECKBOX_THEME.borderError};
       background-color: ${({ theme: { CHECKBOX_THEME } = {} }) => CHECKBOX_THEME && CHECKBOX_THEME.background};
   `}

   .asteriks-icon {
     color: ${({ theme: { CHECKBOX_THEME } = {} }) => CHECKBOX_THEME && CHECKBOX_THEME.colorError};
     font-weight: 600;
 }
`;

export default StyledCheckbox;
