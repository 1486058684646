import styled, { css } from 'styled-components';

const StyledText = styled.p`
    font-weight: normal;
    font-family: 'Open Sans', sans-serif;
    color: ${({ color }) => color || '#66737F'};

    ${({ size, theme: { TEXT_THEME_SIZE } = {} }) => size
    && TEXT_THEME_SIZE && TEXT_THEME_SIZE[size]
    && css`
       font-size: ${TEXT_THEME_SIZE[size].fontSize};
       line-height: ${TEXT_THEME_SIZE[size].lineHeight};
   `}
`;

export default StyledText;
