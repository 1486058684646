export const LABEL_THEME = {
  color: ({ theme }) => theme.textColor2,
  colorError: ({ theme }) => theme.errorColor,
};

export const LABEL_THEME_SIZE = {
  sm: {
    fontSize: '0.875rem',
    lineHeight: '1.55556',
  },
  lg: {
    fontSize: '1rem',
    lineHeight: '1.625',
  },
};
