import React from 'react';
import PropTypes from 'prop-types';
import StyledLink from './styled';

/**
 * Primary UI component for user interaction
 */
export const Hyperlink = ({
  children,
  ...props
}) => (
  <StyledLink
    {...props}
  >
    {children}
  </StyledLink>
);

Hyperlink.propTypes = {
  /** specifies the URL */
  destination: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  externalLinkAlternativeText: PropTypes.string,
  externalLinkTitle: PropTypes.string,
  /** specifies where the link should open. The default behavior is `_self`, which means that the URL will be loaded into the same browsing context as the current one. If the target is `_blank` (opening a new window) `rel='noopener'` will be added to the anchor tag to prevent any potential [reverse tabnabbing attack](https://www.owasp.org/index.php/Reverse_Tabnabbing).
    */
  target: PropTypes.string,
  /** specifies the callback function when the link is clicked */
  onClick: PropTypes.func,
};

Hyperlink.defaultProps = {
  target: '_self',
  onClick: () => {},
  externalLinkAlternativeText: 'Opens in a new window',
  externalLinkTitle: 'Opens in a new window',
};
