import React, { useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { Icon } from '@edx/paragon';
import { StyledInput, TooltipSmall } from './styled';
import { Button } from '../Button';
import { ReactComponent as ShowPasswordIcon } from '../../assets/images/show-icon.svg';
import { ReactComponent as HidePasswordIcon } from '../../assets/images/hide-icon.svg';

import { FormControlFeedback } from '../FormControlFeedback';

/**
 * Primary UI component for user interaction
 */
export const Input = ({
  as,
  options,
  children,
  className,
  controlClassName,
  leadingElement,
  trailingElement,
  floatingLabel,
  hasIcon,
  iconPosition,
  screenReaderTextIcon,
  svgAttrs,
  ...props
}) => {
  const [isVisible, setVisible] = useState(false);
  const [visibleTooltip, setVisibleTooltip] = useState(false);
  const showPasswordRef = useRef();

  const disableFocusOnEsc = (e) => {
    const keyCode = e.which || e.keyCode;
    if (keyCode === 27) {
      e.preventDefault();
      if (showPasswordRef && showPasswordRef.current) {
        showPasswordRef.current.blur();
      }
    }
  };

  const toggleIcon = (e) => {
    e.preventDefault();
    setVisible(!isVisible);
  };

  const showTooltip = (e) => {
    e.preventDefault();
    setVisibleTooltip(!visibleTooltip);
  };

  if (as === 'select') {
    return (
      <StyledInput
        as="select"
        {...props}
      >{children}
      </StyledInput>
    );
  }

  if (props.name === 'password' && hasIcon) {
    const renderScreenReaderText = () => {
      if (Array.isArray(screenReaderTextIcon)) {
        if (isVisible) {
          return screenReaderTextIcon[1];
        }
        return screenReaderTextIcon[0];
      }
      return screenReaderTextIcon;
    };
    return (
      <StyledInput
        value={props.value}
        type={isVisible ? 'text' : 'password'}
        hasIcon={hasIcon}
        iconPosition={iconPosition}
        leadingElement={(
          <Button
            buttonRef={showPasswordRef}
            type="button"
            role="switch"
            aria-checked={isVisible ? 'true' : 'false'}
            onClick={toggleIcon}
            onKeyDown={disableFocusOnEsc}
            onMouseEnter={showTooltip}
            onMouseLeave={showTooltip}
            variant="icon"
            size="sm"
          >
            <Icon
              src={isVisible ? HidePasswordIcon : ShowPasswordIcon}
              screenReaderText={renderScreenReaderText()}
              svgAttrs={svgAttrs}
            />
            {visibleTooltip && (
              <TooltipSmall>
                <span>{renderScreenReaderText()}
                </span>
              </TooltipSmall>
            )}
            <p aria-live="polite" className="sr-only">{isVisible ? `${screenReaderTextIcon[2]} ${props.value}` : screenReaderTextIcon[3]}</p>
          </Button>
        )}
        {...props}
      />
    );
  }

  return (
    <StyledInput
      {...props}
    />
  );
};

Input.Feedback = FormControlFeedback;

Input.propTypes = {
  /**
   * Input type
   */
  as: PropTypes.elementType,
  /**
   * Default value
   */
  defaultValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  /**
   * Value
   */
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  /**
   * Class name
   */
  className: PropTypes.string,
  /**
   * ID
   */
  id: PropTypes.string,
  /**
   * Input name
   */
  name: PropTypes.string,
  /**
   * Controll class name
   */
  controlClassName: PropTypes.string,
  /**
   * Size
   */
  size: PropTypes.oneOf([
    'sm',
    'lg',
  ]),
  /**
   * A string or an element that will be used on a secondary span leveraging the sr-only style
   * for screenreader only text, this value is undefined by default. This value is recommended
   * for use unless the Icon is being used in a way that is purely decorative or provides
   * no additional context for screen reader users. This field should be thought of the same
   * way an alt attribute would be used for image tags.
   * Could be also as array of strings messages for icon.
  */
  screenReaderTextIcon: PropTypes.oneOfType(
    [
      PropTypes.string,
      PropTypes.element,
      PropTypes.arrayOf([PropTypes.string]),
    ],
  ),
  hasIcon: PropTypes.bool,
  leadingElement: PropTypes.node,
  trailingElement: PropTypes.node,
  floatingLabel: PropTypes.node,
  plaintext: PropTypes.bool,
  isValid: PropTypes.bool,
  isInvalid: PropTypes.bool,
  'aria-required': PropTypes.oneOf([
    'true',
    'false',
  ]),
  /**
   * Autocomplete could be as field name, on, off
 */
  autoComplete: PropTypes.string,
  /**
  * Element may be checked for spelling errors.
  */
  spellCheck: PropTypes.oneOf([
    'true',
    'false',
  ]),
  children: PropTypes.node,
  options: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.number, PropTypes.string])),
  iconPosition: PropTypes.oneOf([
    'left',
    'right',
  ]),
  /** HTML element attributes to pass through to the underlying svg element */
  svgAttrs: PropTypes.object, // eslint-disable-line
};

Input.defaultProps = {
  as: 'input',
  className: undefined,
  id: undefined,
  name: undefined,
  controlClassName: undefined,
  defaultValue: undefined,
  value: undefined,
  size: 'sm',
  screenReaderTextIcon: undefined,
  hasIcon: false,
  leadingElement: undefined,
  trailingElement: undefined,
  floatingLabel: undefined,
  plaintext: false,
  isValid: undefined,
  isInvalid: undefined,
  'aria-required': 'false',
  autoComplete: undefined,
  spellCheck: undefined,
  options: undefined,
  iconPosition: undefined,
  svgAttrs: {},
};
