import styled from 'styled-components';
import { Hyperlink } from '@edx/paragon';

const StyledLink = styled(Hyperlink)`
  &[style] {
    text-decoration: none !important;
    text-decoration-line: none !important;
  }

  &&& {
    font-weight: 600;
    font-size: 0.875rem;
    font-family: 'Open Sans', sans-serif;
    padding: 0;
    color: ${({ theme: { LINK_THEME } = {} }) => LINK_THEME && LINK_THEME.color};
    border-bottom: 1px solid ${({ theme: { LINK_THEME } = {} }) => LINK_THEME && LINK_THEME.color};
    text-decoration: none;
    text-decoration-line: none;
  }

  &&&:hover, &&& a:hover, &&& p > a[href]:not(.btn):hover, &&& a.inline-link:hover {
    color: ${({ theme: { LINK_THEME } = {} }) => LINK_THEME && LINK_THEME.colorHover};
    border-bottom: 1px solid ${({ theme: { LINK_THEME } = {} }) => LINK_THEME && LINK_THEME.colorHover};
    text-decoration: none;
    text-decoration-line: none;
  }
`;

export default StyledLink;
