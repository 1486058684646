import styled from 'styled-components';
import { ModalDialog } from '@edx/paragon';

const StyledModal = styled(ModalDialog)`

.pgn__modal-backdrop && {
    opacity: 0.4;
  }
  
  &.pgn__modal {
    border-radius: ${({ theme: { MODAL_THEME } = {} }) => MODAL_THEME && MODAL_THEME.borderRadius};
    border: ${({ theme: { MODAL_THEME } = {} }) => MODAL_THEME && MODAL_THEME.border};
    box-shadow: ${({ theme: { MODAL_THEME } = {} }) => MODAL_THEME && MODAL_THEME.boxShadow};
  }

  &&&.pgn__modal.pgn__modal-scroll-fullscreen {
    margin: 0;
  }

  & .pgn__modal-header {
    padding: 1.875rem 1.875rem 0 1.875rem;

    @media screen and (max-width: 767px) {
      padding: 1rem 1rem 0 1rem;
    }

    &:after {
      content: '';
      width: 100%;
      background-color: #d1d6dc;
      height: 1px;
      margin-top: 1rem;
    }
  }

  &&& .pgn__modal-body {
    padding: 0.75rem 1.875rem 1.875rem 1.875rem;
    overflow: visible;

    @media screen and (max-width: 767px) {
      padding: 0.75rem 1rem 1rem 1rem;
    }
  }

  & .pgn__modal-header .pgn__modal-title {
    font-size: 1.125rem;
    line-height: 1.5rem;
    color: ${({ theme: { MODAL_THEME } = {} }) => MODAL_THEME && MODAL_THEME.titleColor};
    font-family: 'Open Sans', sans-serif;
    font-weight: 600;
  }

  & .btn-icon {
    color: ${({ theme: { MODAL_THEME } = {} }) => MODAL_THEME && MODAL_THEME.closeColor};
    border-radius: 0;
    border: none;
    transform: translateY(15%);

    @media screen and (max-width: 767px) {
      transform: translateY(-15%);
    }

    &:focus {
      box-shadow: none;
      
      [data-whatintent='keyboard'] & {
        border: 2px solid ${({ theme }) => theme && theme.focusColorKeyboard};
      }
    }
  }

  &&& .btn-icon:hover {
    background-color: transparent;
    color: ${({ theme: { MODAL_THEME } = {} }) => MODAL_THEME && MODAL_THEME.closeColor};
  }
`;

export default StyledModal;
