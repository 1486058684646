import styled from 'styled-components';

const StyledFormFrame = styled.div`
  border: 1px solid ${({ theme: { FORM_FRAME_THEME } = {} }) => FORM_FRAME_THEME && FORM_FRAME_THEME.borderColor};
  background-color: ${({ theme: { FORM_FRAME_THEME } = {} }) => FORM_FRAME_THEME && FORM_FRAME_THEME.background};
  margin: auto;
  max-width: ${props => props.width || '35.25rem'};
  width: auto;
  box-shadow: ${({ theme: { FORM_FRAME_THEME } = {} }) => FORM_FRAME_THEME && FORM_FRAME_THEME.boxShadow};
  position: inherit;
  padding: 4rem 0;
  border-radius: 3px;

  @media screen and (max-width: 767px) {
    padding: 1rem 0 1.5rem 0;
  }

  & > * {
      padding: 0 5rem;

      @media screen and (max-width: 767px) {
        padding: 0 1rem;
      }
  }
`;

export default StyledFormFrame;
