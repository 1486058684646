import React from 'react';
import PropTypes from 'prop-types';
import {
  StyledSelectWrapper,
  StyledSelect,
} from './styled';

import { FormControlFeedback } from '../FormControlFeedback';

/**
 * Select component
 */
export const Select = ({
  as,
  options,
  placeholder,
  isInvalid,
  className,
  controlClassName,
  leadingElement,
  trailingElement,
  floatingLabel,
  ...props
}) => (
  <StyledSelectWrapper>
    <StyledSelect
      as="select"
      className={isInvalid ? 'is-invalid' : ''}
      {...props}
    >
      {placeholder && <option key="default" disabled hidden selected>{placeholder}</option>}
      {options && options.map((optionVal) => (
        <option key={optionVal.value} value={optionVal.value}>{optionVal.label}</option>
      ))}
    </StyledSelect>
    <span className="focus-select" />
  </StyledSelectWrapper>
);

Select.Feedback = FormControlFeedback;

Select.propTypes = {
  /**
   * Input type
  */
  as: PropTypes.elementType,
  /**
   * Placeholder text
  */
  placeholder: PropTypes.string,
  /**
   * Selected value by default on init
  */
  defaultValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  /**
   * Value
   */
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  /**
   * Class name
  */
  className: PropTypes.string,
  /**
   * ID
   */
  id: PropTypes.string,
  /**
   * Controll class name
  */
  controlClassName: PropTypes.string,
  /**
   * Size
  */
  size: PropTypes.oneOf([
    'sm',
    'lg',
  ]),
  leadingElement: PropTypes.node,
  trailingElement: PropTypes.node,
  floatingLabel: PropTypes.node,
  plaintext: PropTypes.bool,
  isValid: PropTypes.bool,
  isInvalid: PropTypes.bool,
  'aria-required': PropTypes.oneOf([
    'true',
    'false',
  ]),
  /**
   * Autocomplete could be as field name, on, off
 */
  autoComplete: PropTypes.string,
  /**
   * Array of object for options list inside select input
   * [{value: '', label: 'example label'}]
 */
  options: PropTypes.arrayOf(PropTypes.any).isRequired,
};

Select.defaultProps = {
  as: 'select',
  className: undefined,
  id: undefined,
  controlClassName: undefined,
  defaultValue: undefined,
  value: undefined,
  size: 'sm',
  leadingElement: undefined,
  trailingElement: undefined,
  floatingLabel: undefined,
  plaintext: false,
  isValid: undefined,
  isInvalid: undefined,
  'aria-required': 'false',
  autoComplete: undefined,
  options: undefined,
  placeholder: undefined,
};
