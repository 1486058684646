import React from 'react';
import PropTypes from 'prop-types';
import { ModalDialog as _ModalDialog } from '@edx/paragon';
import StyledModal from './styled';

/**
 * Modal Dialog
 */
export const ModalDialog = ({
  children,
  modalId,
  ...props
}) => (
  <StyledModal
    id={modalId}
    {...props}
  >
    {children}
  </StyledModal>
);

ModalDialog.Header = _ModalDialog.Header;
ModalDialog.Title = _ModalDialog.Title;
ModalDialog.Footer = _ModalDialog.Footer;
ModalDialog.CloseButton = _ModalDialog.CloseButton;
ModalDialog.Body = _ModalDialog.Body;
ModalDialog.Hero = _ModalDialog.Hero;

ModalDialog.propTypes = {
  children: PropTypes.node.isRequired,
  /**
     * The aria-label of the dialog
     */
  title: PropTypes.string.isRequired,
  /**
     * A callback to close the modal dialog
     */
  onClose: PropTypes.func.isRequired,
  /**
     * Is the modal dialog open or closed
     */
  isOpen: PropTypes.bool,
  /**
     * The close 'x' icon button in the top right of the dialog box
     */
  hasCloseButton: PropTypes.bool,
  /**
     * Sizes determine the maximum width of the dialog box
     */
  size: PropTypes.oneOf(['sm', 'md', 'lg', 'xl', 'fullscreen']),
  /**
     * The visual style of the dialog box
     */
  variant: PropTypes.oneOf(['default', 'warning', 'danger', 'success', 'dark']),
  /**
     * The label supplied to the close icon button if one is rendered
     */
  closeLabel: PropTypes.string,
  modalId: PropTypes.string,
  className: PropTypes.string,
  /**
     * Determines where a scrollbar should appear if a modal is too large for the
     * viewport. When false, the ModalDialog.Body receives a scrollbar, when true
     * the browser window itself receives the scrollbar.
     */
  isFullscreenScroll: PropTypes.bool,
  /**
     * to show full screen view on mobile screens
     * */
  isFullscreenOnMobile: PropTypes.bool,
};

ModalDialog.defaultProps = {
  isOpen: false,
  hasCloseButton: true,
  size: 'md',
  variant: 'default',
  closeLabel: 'Close',
  className: undefined,
  modalId: undefined,
  isFullscreenScroll: false,
  isFullscreenOnMobile: false,
};
