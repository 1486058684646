import React from 'react';
import PropTypes from 'prop-types';
import StyledFormControlFeedback from './styled';

const FORM_TEXT_TYPES = {
  DEFAULT: 'default',
  VALID: 'valid',
  INVALID: 'invalid',
  WARNING: 'warning',
  CRITERIA_EMPTY: 'criteria-empty',
  CRITERIA_VALID: 'criteria-valid',
  CRITERIA_INVALID: 'criteria-invalid',
};

/**
 * Primary UI component for user interaction
 */
export const FormControlFeedback = ({
  children,
  controlClassName,
  ...props
}) => (

  <StyledFormControlFeedback
    {...props}
    className={`${controlClassName}-${props.type}-feedback`}
  >
    {children}
  </StyledFormControlFeedback>
);

FormControlFeedback.propTypes = {
  /**
   * Has icon ?
  */
  hasIcon: PropTypes.bool,
  /**
   * Type
  */
  type: PropTypes.oneOf(Object.values(FORM_TEXT_TYPES)),
  /**
   * Icon
  */
  icon: PropTypes.node,
  /**
   * Children
  */
  children: PropTypes.node.isRequired,
  /**
   * Class name
  */
  className: PropTypes.string,
  /**
   * Class name included in class for identify feedback element,
   * for example for unit tests. The name should be as input field's id.
  */
  controlClassName: PropTypes.string.isRequired,
  /**
   * Is muted (disabled) ?
  */
  muted: PropTypes.bool,
  /**
   * WCAG aria-live prop
   *
   * aria-live="polite" Any region which receives updates that are important for the user to receive,
   * but not so rapid as to be annoying, should receive this attribute.
   * The screen reader will speak changes whenever the user is idle.
   *
   * aria-live="assertive" should only be used for time sensitive,
   * critical notifications that absolutely require the user's immediate attention.
   * Generally, a change to an assertive live region will interrupt any announcement
   * a screen reader is currently making.
   * As such, it can be extremely annoying and disruptive and should only be used sparingly.
   *
   * As aria-live="off" is the assumed default for elements,
   * it should not be necessary to set this explicitly,
   * unless you're trying to suppress the announcement of
   * elements which have an implicit live region role (such as role="alert").
  */
  'aria-live': PropTypes.oneOf(['assertive', 'polite', 'off']),
  /**
   * WCAG aria-atomic prop has to be true for aria-live 'assertive'
  */
  'aria-atomic': PropTypes.oneOf(['true', 'false']),
  /**
   * Margin top, by default margin top is .25rem
   */
  mt: PropTypes.string,
};

FormControlFeedback.defaultProps = {
  hasIcon: false,
  type: undefined,
  icon: undefined,
  className: undefined,
  controlClassName: 'text',
  muted: false,
  'aria-live': undefined,
  'aria-atomic': undefined,
  mt: '.25rem',
};
