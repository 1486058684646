export const INPUT_THEME = {
  borderColor: ({ theme }) => theme.textColor3,
  color: ({ theme }) => theme.textColor1,
  borderColorHover: ({ theme }) => theme.textColor3,
  borderColorActive: '#008080',
  borderColorError: ({ theme }) => theme.errorColor,
  borderColorFocus: '#DEE2E6',
  borderColorOutline: '#DEE2E6',
};

export const INPUT_THEME_SIZE = {
  sm: {
    height: '2.375rem',
    fontSize: '1rem',
    lineHeight: '1.125',
  },
  lg: {
    height: '2.75rem',
    fontSize: '1.125rem',
    lineHeight: '1.25',
  },
};
